
import { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
	name: "MatchDetail",
	data() {
		return {
			boxScore: [],
			matchDetails: [],
			teamOnBoxScore: -1,
		};
	},
	computed: {
		boxScoreFiltered(): any {
			if (this.teamOnBoxScore === -1) return this.boxScore;
			return this.boxScore.filter(
				(player: any) => player.player.team_id === this.teamOnBoxScore
			);
		},
		matchDetail() {
			return this.matchDetails;
		},
		playerInfo() {
			return this.playerInfos;
		},
	},
	methods: {
		playerDetail(id: number): void {
			store.commit("setPlayerId", id);
			this.$router.push("/player-detail");
		},
		getImgUrl(id: number): any {
			if (id) {
				return require("@/assets/teamLogos/team_" + id + ".png");
			}
		},
		async getBoxScore() {
			await store.dispatch("getBoxScore").then(() => {
				this.boxScore = store.getters.boxScore;
			});
		},
		async getMatchDetails() {
			await store.dispatch("getMatchDetails").then(() => {
				this.matchDetails = store.getters.matchDetails;
			});
		},
	},
	async beforeMount() {
		this.getBoxScore();
		this.getMatchDetails();
	},
});
